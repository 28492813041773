import useBaseUtils from "~/functions/useBaseUtils";
export default defineComponent({
  props: {
    symbol: {
      type: String,
      "default": "SPY"
    },
    tid: {
      type: String,
      "default": "6179710" // 2594158
    },
    ts: {
      type: Array,
      "default": function _default() {
        return [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0];
      }
    },
    hidePrice: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      dev = _useBaseUtils.dev,
      I18nFn = _useBaseUtils.I18nFn;
    return {
      dev: dev,
      I18nFn: I18nFn
    };
  },
  computed: {
    stateKey: function stateKey() {
      // const type = this.wltype === "watchlist" ? "watchlist" : "ciq"
      // return type
      return "watchlist";
    },
    usd: function usd() {
      return false;
    },
    quotes: function quotes() {
      // return {}
      return this.$store.state.iex.lastQuotes || {};
    },
    ciqData: function ciqData() {
      // return {}
      return this.$store.state.watchlist.ciq || {};
    }
  }
});